<template>
  <v-container>
    <v-dialog
      :fullscreen="isPhone"
      width="900"
      overlay-opacity=".95"
      v-model="showReorder"
      persistent
    >
      <ReorderList
        :items="workouts"
        title="Re-order workouts"
        name="workouts"
        @clickedDone="doneReorder"
        @clickedCancel="showReorder = false"
      />
    </v-dialog>

    <v-form ref="ProgramForm" name="ProgramForm">
      <BaseScreenHeader title="Add Program" screenInfo="PRG-003" />
      <v-sheet rounded class="transparent pa-2 charcoalTile">
        <v-card-actions class="pa-0">
          <v-spacer />
          <v-switch
            dark
            ref="public"
            false-value="No"
            true-value="Yes"
            label="Public?"
            color="progressActive"
            class="pa-0"
            v-model="program.public"
          />
        </v-card-actions>
        <v-card-actions class="pa-0">
          <v-spacer />
          <v-select
            dark
            chips
            :key="switchNum"
            filled
            color="success"
            v-model="type"
            :items="programTypes"
            label="Program Type"
            item-text="name"
            item-value="id"
            prepend-inner-icon="mdi-blur-radial"
            return-object
          />
        </v-card-actions>
        <v-card-text class="pa-0">
          <v-sheet class="pt-1  transparent paper--text">
            <v-card-title class="py-1">
              Name and Description
            </v-card-title>
          </v-sheet>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                dark
                filled
                v-model="program.name"
                ref="name"
                :error-messages="nameErrors"
                :counter="50"
                label="Workout Name"
                prepend-inner-icon="mdi-dumbbell"
                @input="$v.program.name.$touch()"
                @blur="$v.program.name.$touch()"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-textarea
                dark
                filled
                v-model="program.description"
                ref="description"
                :error-messages="descriptionErrors"
                :counter="400"
                :rows="3"
                label="Description"
                prepend-inner-icon="mdi-text"
                @input="$v.program.description.$touch()"
                @blur="$v.program.description.$touch()"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-0">
          <v-spacer />
          <BaseActionButton
            text
            dark
            class="paper--text"
            :disabled="$v.$anyError || workouts == 0"
            @clickedThis="saveProgram"
          />
        </v-card-actions>
        <v-sheet class="transparent charcoalTile px-2 my-4">
          <v-card-actions class="px-0 pb-0">
            <span class="title  paper--text">
              Sel. Workouts
            </span>
            <v-badge
              :content="workouts.length"
              :value="workouts.length"
              color="transparent paper--text"
              overlap
            >
              <v-icon large color="paper">
                mdi-checkbox-multiple-marked-circle
              </v-icon>
            </v-badge>
            <v-spacer />
            <v-btn
              text
              v-if="workouts.length > 0"
              class=" silver--text "
              @click="showReorder = true"
            >
              Re-order
            </v-btn>
          </v-card-actions>
          <v-card-text class="pa-0">
            <WorkoutChip
              :type="program.type"
              :workouts="workouts"
              @remove="removeWorkout"
            />
          </v-card-text>
        </v-sheet>
        <WorkoutList
          :type="program.type"
          :workoutsList="workouts"
          @add_workout="addWorkout"
        />
      </v-sheet>
    </v-form>
  </v-container>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { appConfig, builder } from '@/store/helpers.js'
import axios from 'axios'
import util from '@/mixins/util.js'

const WorkoutList = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/WorkoutsList.vue')
const WorkoutChip = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/WorkoutChip.vue')
const ReorderList = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/ReorderList.vue')

export default {
  components: {
    WorkoutList,
    WorkoutChip,
    ReorderList
  },
  name: 'AddProgram',
  mixins: [util, validationMixin],
  data: () => ({
    showReorder: false,
    tagsize: 'small',
    type: null,
    loaded: false,
    switchNum: 0,
    program: {},
    programTypes: [],
    workout: {},
    workouts: [],
    name: '',
    oldname: '',
    description: ''
  }),
  beforeMount() {
    this.loadProgramTypes()
  },
  validations: {
    program: {
      name: {
        required
      },
      description: {
        required
      }
    }
  },
  computed: {
    ...appConfig,
    ...builder,
    nameErrors() {
      const errors = []
      if (!this.$v.program.name.$dirty) return errors
      !this.$v.program.name.required && errors.push('Workout name is required.')
      return errors
    },
    descriptionErrors() {
      const errors = []
      if (!this.$v.program.description.$dirty) return errors
      !this.$v.program.description.required &&
        errors.push('Description is required.')
      return errors
    }
  },
  methods: {
    addWorkout(workout) {
      if (this.workouts == null) this.workouts = new Array()

      this.workouts.push(workout)
    },
    doneReorder() {
      this.showReorder = false
      this.saveProgram()
    },
    toggleDetails() {
      this.showDetails = !this.showDetails
    },
    removeWorkout(workout) {
      this.workouts = this.workouts.filter(el => {
        return el.workoutid != workout.workoutid
      })
    },
    loadProgramTypes() {
      return axios
        .get(this.baseURL + '/util/glossary/programs', {})
        .then(response => {
          if (response.status == 200) {
            this.programTypes = response.data.data
            this.type = this.programTypes[0]
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    saveProgram() {
      window.scrollTo(0, 0)
      return axios
        .post(this.baseURL + '/program', {
          programid: 'New',
          name: this.program.name,
          description: this.program.description,
          public: this.program.public,
          type: this.type.name,
          workouts: this.workouts
        })
        .then(response => {
          if (response.status == 200) {
            this.toast('Program was successfully added.')
            this.$router.push('/myprograms')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>

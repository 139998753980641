<template>
  <v-container>
    <BaseScreenHeader
      title="Use Program"
      screenInfo="PRG-004"
      :showBack="true"
    />

    <v-sheet class="charcoal">
      <v-card-title :class="textSize + ' paper--text pb-0'">
        <v-divider class="charcoal lighten-1" />
        <span class="mx-2">{{ programInstance.name }}</span>
        <v-divider class="charcoal lighten-1" />
      </v-card-title>

      <v-card-actions :class="textSizeSmall + ' py-0'">
        <v-divider />
        <v-sheet rounded class="charcoal silver--text pa-2 pb-0 mx-2">
          ROUND
          {{ programInstance.current_round }}
        </v-sheet>
        <v-divider v-if="!isPhone" />
      </v-card-actions>
      <v-timeline v-if="workouts.length > 0" align-top dense>
        <v-timeline-item
          color="charcoal"
          fill-dot
          small
          :left="index % 2 == 0"
          :right="index % 2 != 0"
          v-for="(item, index) in workouts"
          :key="item.id"
          :class="isPhone ? 'ml-n6' : ''"
        >
          <template v-slot:icon>
            <v-icon medium :color="index == 0 ? ' nicegreen' : ' paper'">
              {{ iconNumber(index) }}
            </v-icon>
          </template>
          <template v-slot:opposite>
            <span class=" silver--text">
              Round {{ item.current_round }} of
              {{ programInstance.current_round }}
            </span>
          </template>
          <v-card
            class="charcoal charcoalTile ml-n3 pa-2 link mt-n2 rounded-lg"
          >
            <v-card-actions class="silver--text px-0 pt-0">
              <span :class="index == 0 ? ' nicegreen--text' : ' paper--text'">
                {{ item.workout_name }}
              </span>
            </v-card-actions>

            <v-card-actions class="pt-0 px-0 mt-n2">
              <span title="Type" :class="textSizeXSmall + ' silver--text'">
                Completed: {{ item.current_round }} rounds
              </span>
              <v-spacer />
              <span title="Type" :class="textSizeXSmall + ' mx-2 paper--text'">
                {{ item.workout_type }}
              </span>
            </v-card-actions>
            <v-card-actions class="pb-0 px-0 ml-n3">
              <BaseActionButton
                text
                small
                color="silver"
                icon="mdi-step-backward"
                label="Back"
                title="Undo Round"
                @clickedThis="undoRound(item)"
              />
              <BaseActionButton
                text
                small
                color="silver"
                icon="mdi-skip-next-circle"
                label="Skip"
                title="Skip Workout"
                @clickedThis="skipWorkout(item)"
              />

              <v-spacer />
              <v-btn
                height="40"
                large
                label="Start Workout"
                :color="index == 0 ? 'nicegreen' : 'paper'"
                class="rounded"
                tonal
                @click="confirmStart(item)"
              >
                <v-icon color="black" large>
                  mdi-play-circle
                </v-icon>
                <span class="text-h6"> Start</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-timeline-item>
      </v-timeline>
      <BaseLoading :loaded="loaded" />
    </v-sheet>

    <StartWorkout
      :show="showVariationDialog"
      @cancel="showVariationDialog = false"
      :workout="selectedItem"
      :programid="programid"
    />
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig, builder } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const StartWorkout = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/StartWorkout.vue')
export default {
  components: { StartWorkout },
  mixins: [util],
  name: 'ViewProgram',
  data: () => ({
    workouts: [],
    variations: [],
    selectedItem: {},
    selectedVariation: null,
    programInstance: {},
    loaded: false,
    showVariationDialog: false,
    showVariationDialog1: false,
    search: null,
    switchNum: 0
  }),
  beforeMount() {
    this.loaded = false
    this.loadPage()
  },
  mounted() {},

  props: {
    programid: String
  },

  computed: {
    ...appConfig,
    ...builder
  },
  methods: {
    loadPage() {
      this.loaded = false
      let url = '/program/instance/' + this.programid
      return axios.get(this.baseURL + url, {}).then(response => {
        if (response.status == 200) {
          this.programInstance = response.data.data
          this.workouts = JSON.parse(this.programInstance.workouts)
          this.loaded = true
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            {
              root: true
            }
          )
          return
        }
      })
    },

    confirmStart(workout) {
      this.selectedItem = workout
      let url = '/workout/variations/'
      return axios
        .get(this.baseURL + url + workout.workoutid, {})
        .then(response => {
          if (response.status == 200) {
            this.variations = response.data.data

            this.selectedVariation = null
            this.showVariationDialog = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    startWorkout(workout) {
      return axios
        .post(this.baseURL + '/program/workout/start', {
          programworkoutinstanceid: workout.id,
          skip: false,
          from_workout: false
        })
        .then(response => {
          if (response.status == 200) {
            this.toast('Starting Workout.')
            let startWhat =
              this.selectedVariation == null
                ? this.selectedItem
                : this.selectedVariation
            this.goTo(startWhat)
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    skipWorkout(workout) {
      return axios
        .post(this.baseURL + '/program/workout/start', {
          programworkoutinstanceid: workout.id,
          skip: true,
          from_workout: false
        })
        .then(response => {
          if (response.status == 200) {
            this.toast('Workout was succesfully skipped.')
            this.loadPage()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    undoRound(workout) {
      return axios
        .post(this.baseURL + '/program/workout/rollback', {
          programworkoutinstanceid: workout.id
        })
        .then(response => {
          if (response.status == 200) {
            this.toast('Workout was succesfully skipped.')
            this.loadPage()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    goTo(workout) {
      let id = null
      if (workout.hasOwnProperty('variationid')) id = workout.variationid
      else id = workout.workoutid
      let path = '/workouts/start/' + id
      if (workout.workout_type == 'Circuit') path = '/workouts/start/hiit/' + id
      else if (workout.workout_type == 'Hybrid') {
        let type = 'rft'
        if (workout.xmode == 'time') type = 'rft'
        else type = workout.xmode
        path = '/workouts/start/' + type + '/' + id
        // emom, rft, amrap
      }
      this.$router.push({
        path: path
      })
    }
  }
}
</script>

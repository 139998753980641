<template>
  <v-container class="px-1">
    <BaseScreenHeader
      title="My Programs"
      screenInfo="PRG-001"
      :showBack="true"
    />
    <template v-if="loaded && loadedList.length == 0">
      <v-sheet class=" mt-2 charcoal">
        <v-sheet class=" transparent mx-2" :style="charcoalTile">
          <v-card-text :class="textSizeXSmall + ' px-0 silver--text'">
            <v-icon class="silver--text" large>
              mdi-information-outline
            </v-icon>
            <v-spacer />
            Programs allow you to organize your workouts based on your specific
            goals. Using a program makes it easy to determine what workout you
            need to complete next. Get started with the most straightforward
            type of program, "Lazy Susan," which cycles through a set number of
            predefined workouts.
            <v-spacer />
          </v-card-text>
          <v-card-text :class="textSizeSmall + ' px-0 progressActive--text'">
            Get started by adding a new program.
          </v-card-text>
          <v-card-actions>
            <BaseActionButton
              v-if="showPublic"
              label="Explore Public"
              title="Explore Public Community Programs"
              icon="mdi-search-web"
              class="charcoal progressActive--text"
              :large="!isPhone"
              text
              href
              @clickedThis="$router.push('/programs/database')"
            />
            <v-spacer />
            <BaseActionButton
              icon="mdi-plus-circle-outline"
              label="Add"
              title="Create Your First Program"
              plain
              :class="textSizeSmall + ' charcoal paper--text'"
              large
              @clickedThis="
                $router.push({
                  path: '/programs/add'
                })
              "
            />
          </v-card-actions>
        </v-sheet>
      </v-sheet>
    </template>
    <template v-else>
      <span
        v-if="scrollPosition <= 50"
        class="leftTools charcoal charcoalTileMenu fabsearch"
      >
        <v-btn
          color="transparent silver--text "
          fab
          dark
          class="elevation-0 btn-fix "
          :retain-focus-on-click="false"
          @click="toggleFavorites"
        >
          <v-icon color="accent" class="ml-2" large>
            {{ favoritesOnly ? 'mdi-heart' : 'mdi-heart-outline' }}
          </v-icon>
        </v-btn>
        <v-btn
          color="transparent silver--text  "
          fab
          dark
          class="elevation-0 btn-fix "
          :retain-focus-on-click="false"
          @click="toggleSearch"
        >
          <v-icon class="ml-2" large>
            {{ showSearching ? 'mdi-chevron-left' : 'mdi-magnify' }}
          </v-icon>
        </v-btn>
      </span>
      <v-icon
        v-if="scrollPosition <= 50 && loaded"
        large
        color="paper"
        :class="isPhone ? 'addBtnPhone' : 'addBtn'"
        @click="addMore"
      >
        mdi-plus-circle-outline
      </v-icon>
      <span :class="isPhone ? 'searchBoxPhone' : 'searchBox'">
        <v-sheet
          rounded
          v-if="showSearching && scrollPosition <= 50"
          class="charcoalTileMenu transparent pa-2 ml-n2"
        >
          <BaseSearch
            dark
            :items="loadedList"
            :searchIn="['name', 'type']"
            :label="loadedList.length + ' Programs'"
            @search_results="setResults"
          />
        </v-sheet>
      </span>
      <v-card flat tile class=" pa-0 charcoal my-4 mb-2">
        <BaseFreeTier v-if="loaded && loadedList.length > 0" class="pl-4" />

        <BaseLoading :loaded="loaded" />
        <v-row
          v-if="programs.length > 0"
          dense
          :class="showSearching ? 'searchResults' : 'searchResultsNosearch'"
        >
          <v-col
            class="mt-2"
            v-for="program in programs"
            :key="program.id"
            cols="12"
            md="6"
          >
            <v-hover v-slot="{ hover }" open-delay="150" close-deplay="200">
              <v-sheet
                rounded
                class=" charcoalTileMenu transparent paper--text py-2 px-1 mx-1"
                :elevation="hover ? 3 : 1"

              >
                <v-card class="transparent px-0" flat tile>
                  <v-card-actions class=" py-0 mb-n2">
                    <v-spacer />
                    <v-icon
                      v-if="program.is_favorite == 'Yes'"
                      color="accent"
                      large
                      title="Remove From Favorites"
                      @click="removeFavorite(program)"
                    >
                      mdi-heart
                    </v-icon>
                    <v-icon
                      v-else
                      color="accent"
                      title="Add To Favorites"
                      large
                      @click="addFavorite(program)"
                    >
                      mdi-heart-outline
                    </v-icon>
                    <v-spacer />
                  </v-card-actions>
                  <v-card-actions class=" pa-0">
                    <v-divider class="charcoal lighten-1" />
                    <v-sheet rounded class="transparent pa-2">
                      <BaseActionButton
                        :label="program.active == 'Yes' ? 'Continue' : 'Start'"
                        :title="
                          program.active == 'Yes'
                            ? 'Continue Program: ' + program.name
                            : 'Start Program: ' + program.name
                        "
                        text
                        :color="
                          program.active == 'Yes' ? 'progressActive' : 'paper'
                        "
                        :class="' ' + textSize"
                        icon="mdi-play-circle"
                        @clickedThis="startProgram(program)"
                      />
                    </v-sheet>
                    <v-divider class="charcoal lighten-1" />
                  </v-card-actions>
                  <v-card-title :class="textSize + ' paper--text pb-4 pt-0'">
                    <v-spacer />
                    <span>{{ trimName(program) }}</span>
                    <v-spacer />
                  </v-card-title>
                  <template>
                    <v-card-actions class="caption  silver--text pt-0 px-2">
                      Used {{ program.used }} times, copied
                      {{ program.copied }} times.
                      <v-spacer />
                      <span
                        v-if="program.public == 'Yes'"
                        :class="textSizeXSmall + ' success--text link'"
                        @click="manageVisibility(program)"
                      >
                        PUBLIC
                      </span>
                      <span
                        v-else
                        :class="textSizeXSmall + ' orange--text link'"
                        @click="manageVisibility(program)"
                      >
                        PRIVATE
                      </span>
                    </v-card-actions>
                  </template>
                  <v-card-actions class="pb-2 pt-0">
                    <v-divider class="charcoal lighten-1" />
                    <v-badge
                      color="progressActive charcoal--text"
                      inline
                      :content="program.workouts.length + ' Workouts'"
                    >
                    </v-badge>
                    <v-divider class="charcoal lighten-1" />
                  </v-card-actions>

                  <v-card-text
                    :class="textSizeXSmall + ' paper--text py-0 pl-2'"
                  >
                    {{ program.description }}
                  </v-card-text>
                  <v-card-text class="pt-0 pl-0">
                    <v-list class="transparent" dense subheader>
                      <v-list-item
                        v-for="(workout, index) in program.workouts"
                        :key="index"
                        class="pl-1"
                      >
                        <v-row dense>
                          <v-col cols="12">
                            <v-badge
                              color="progressActive charcoal--text"
                              left
                              inline
                              :content="workout.sort"
                            >
                              <div
                                :class="
                                  textSizeXSmall +
                                    ' ml-1 silver--text text--darken-1 link'
                                "
                                :title="'View Details for ' + workout.name"
                                @click="showWorkout(workout.id)"
                              >
                                {{ workout.name }}
                                <span class="caption silver--text">
                                  ({{ workoutInfo(workout) }})
                                </span>
                              </div>
                            </v-badge>
                          </v-col>
                        </v-row>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                  <v-card-actions class="pa-0">
                    <BaseActionButton
                      :large="!isPhone"
                      icon="mdi-pencil-outline"
                      :title="'Edit program: ' + program.name"
                      label="Edit"
                      plain
                      color="silver"
                      @clickedThis="editprogram(program)"
                    />
                    <v-spacer />
                    <BaseActionButton
                      :large="!isPhone"
                      icon="mdi-pencil-box-multiple-outline"
                      :title="'Duplicate program: ' + program.name"
                      label="Duplicate"
                      plain
                      color="paper"
                      @clickedThis="copyProgram(program)"
                    />
                  </v-card-actions>
                </v-card>
              </v-sheet>
            </v-hover>
          </v-col>
        </v-row>
      </v-card>
    </template>

    <LoadingDialog
      :show="!loaded && currentPage == 0"
      title="LOADING Programs..."
      color="accent"
    />

    <BaseUpgradeWarning
      :show="showUpgradeWarning"
      @cancel="showUpgradeWarning = false"
      limitation="Maximum one program."
      remediation="edit the existing program"
    />
    <v-dialog
      v-model="showCopyDialog"
      persistent
      width="700"
      :fullscreen="isPhone"
    >
      <v-card tile class="d-flex flex-column">
        <v-sheet color="lightHeader" class="charcoal--text">
          <v-card-title class="title">
            Duplicate program?
            <v-spacer />
            <BaseCloseDialog @cancel="showCopyDialog = false" />
          </v-card-title>
        </v-sheet>
        <v-card-text class="px-2 mt-2">
          This allows you to duplicate one of your programs. You will then be
          able to tweak it and use it as you see fit.
          <v-card-text class="title accent--text px-0">
            Current Program Name
            <div
              class="body-1 primary--text"
              v-html="selectedProgram.name"
            ></div>
          </v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="selectedProgram.newname"
                ref="name"
                :counter="50"
                label="New program Name"
                prepend-icon="mdi-rename-box"
                @focus="$event.target.select()"
              />
            </v-col>
          </v-row>
          <v-card-text class="body-1 pt-5 px-0">
            Update the program name above and then click the
            <span class="accent--text">Duplicate </span> button.
          </v-card-text>
        </v-card-text>
        <v-spacer />
        <v-card-actions class="px-0">
          <BaseActionButton
            icon="mdi-close"
            label="Cancel"
            plain
            @clickedThis="showCopyDialog = false"
          />
          <v-spacer />
          <BaseActionButton
            :disabled="isEmpty(selectedProgram.newname)"
            label="Duplicate"
            plain
            title="Duplicate program"
            color="success"
            icon="mdi-pencil-box-multiple-outline"
            @clickedThis="copyNow()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showVisibilityDialog"
      persistent
      width="700"
      :fullscreen="isPhone"
      overlay-color="primary"
      overlay-opacity="70"
    >
      <v-card tile class="charcoal d-flex flex-column">
        <v-sheet color="transparent" class="charcoal--text">
          <v-card-title
            :class="
              selectedProgram.public == 'Yes' ? 'orange--text' : 'success--text'
            "
          >
            <span class="silver--text pr-2">Make program</span>
            {{ selectedProgram.public == 'Yes' ? 'PRIVATE' : 'PUBLIC' }} ?
          </v-card-title>
        </v-sheet>
        <v-spacer />
        <v-card-actions class="px-0">
          <BaseActionButton
            icon="mdi-close"
            label="No"
            plain
            color="silver"
            @clickedThis="cancelVisibility"
          />
          <v-spacer />
          <BaseActionButton
            label="Yes"
            plain
            color="paper"
            icon="mdi-checkbox-outline"
            @clickedThis="saveVisibility()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig, builder } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const LoadingDialog = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/Loading.vue')
import debounce from 'lodash/debounce'
export default {
  components: { LoadingDialog },
  mixins: [util],
  name: 'ListPrograms',
  data: () => ({
    name: '',
    oldname: '',
    description: '',
    programs: [],
    loadedList: [],
    selectedRepScheme: {},
    selectedProgram: {},
    selectedworkout: '',
    showWorkoutInfo: false,
    showConvertDialog: false,
    showRepSchemeDialog: false,
    showCopyDialog: false,
    showVisibilityDialog: false,
    showDetails: false,
    showSearching: false,
    showWorkouts: true,
    showSuccess: false,
    showPublic: false,
    loaded: false,
    refresh: false,
    favoritesOnly: false,
    showUpgradeWarning: false,
    search: null,
    switchNum: 0,
    favoritesNum: 0,
    debouncedScroll: null,
    debounce: null,
    pagesize: 50,
    currentPage: 0,
    scrollPosition: 0,

    bottom: false
  }),
  beforeMount() {
    this.loaded = false
    this.currentPage = 0
    this.loadPage()
  },
  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 20)
    window.addEventListener('scroll', this.handleDebouncedScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleDebouncedScroll)
  },
  watch: {
    bottom(bottom) {
      if (bottom && !this.favoritesOnly) {
        this.currentPage++
        this.loadPage()
      }
    }
  },
  computed: {
    itemsCount() {
      return this.programs.length
    },
    favoritesLabel() {
      let start = 'Favorites Only'
      let end = this.favoritesNum > 0 ? '(' + this.favoritesNum + ')' : ''
      return start + end
    },
    ...appConfig,
    ...builder
  },
  methods: {
    toggleSearch() {
      this.showSearching = !this.showSearching
      if (this.showSearching) this.search = ''
    },
    addMore() {
      if (!this.is_pro && this.loadedList.length > 0) {
        this.showUpgradeWarning = true
      } else
        this.$router.push({
          path: '/programs/add'
        })
    },
    workoutInfo(workout) {
      let xmode = workout.xmode != null ? ', ' + workout.xmode : ''
      let num_ex = ', ' + workout.num_exercises + ' exercises'
      return workout.type + xmode + num_ex
    },
    setResults(value) {
      this.programs = value
    },
    handleScroll(/*event*/) {
      this.isUserScrolling = window.scrollY > 0
      this.scrollPosition =
        window.pageYOffset ||
        window.scrollY ||
        document.body.scrollTop ||
        document.documentElement.scrollTop
      this.bottom = this.bottomVisible()
    },
    manageVisibility(program) {
      this.selectedProgram = program
      this.showVisibilityDialog = true
    },
    cancelVisibility() {
      this.selectedProgram = {}
      this.showVisibilityDialog = false
    },
    showWorkout(workoutid) {
      this.switchNum++
      this.selectedworkout = workoutid
      this.showWorkoutInfo = true
    },
    cancelInfo() {
      this.selectedworkout = ''
      this.showWorkoutInfo = false
    },

    editprogram(program) {
      this.$router.push({
        path: '/programs/edit/' + program.id
      })
    },
    copyProgram(program) {
      if (!this.is_pro && this.loadedList.length > 0) {
        this.showUpgradeWarning = true
      } else {
        this.selectedProgram = program
        this.selectedProgram.newname = 'Copy Of ' + this.selectedProgram.name
        this.showCopyDialog = true
      }
    },

    copyNow() {
      return axios
        .post(this.baseURL + '/program', {
          programid: 'New',
          name: this.selectedProgram.newname,
          description: this.selectedProgram.description,
          public: this.selectedProgram.public,
          type: this.selectedProgram.type,
          workouts: this.selectedProgram.workouts
        })
        .then(response => {
          if (response.status == 200) {
            this.showCopyDialog = false
            this.toast(
              'Program ' + this.selectedProgram.newname + ' was copied!'
            )
            this.currentPage = 0
            this.refresh = true
            this.loadPage()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    saveVisibility() {
      this.selectedProgram.public =
        this.selectedProgram.public == 'Yes' ? 'No' : 'Yes'
      this.cancelVisibility()
      return axios
        .post(this.baseURL + '/program/visibility', {
          program: this.selectedProgram
        })
        .then(response => {
          if (response.status == 200) {
            this.toast('Program visibility was succesfully set.')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    addFavorite(program) {
      return axios
        .post(this.baseURL + '/program/favorite', {
          programid: program.id
        })
        .then(response => {
          if (response.status == 200) {
            this.switchNum++
            program.is_favorite = 'Yes'
            this.toast(
              'Program: ' +
                program.name +
                ' was succesfully added to favorites.'
            )
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    removeFavorite(program) {
      return axios
        .delete(this.baseURL + '/program/favorite/' + program.id, {})
        .then(response => {
          if (response.status == 200) {
            this.switchNum++
            program.is_favorite = 'No'
            this.toast(
              'Program: ' +
                program.name +
                ' was succesfully removed from favorites.'
            )
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    toggleFavorites() {
      this.favoritesOnly = !this.favoritesOnly
      if (this.favoritesOnly) {
        this.programs = this.loadedList.filter(function(item) {
          return item.is_favorite == 'Yes'
        })
        this.favoritesNum = this.programs.length
      } else {
        this.programs = this.loadedList
        this.favoritesNum = 0
      }
    },
    startProgram(program) {
      if (program.active == 'Yes') {
        this.$router.push('/program/view/' + program.id)
      } else {
        let url = '/program/start'
        return axios
          .post(this.baseURL + url, {
            programid: program.id,
            workouts: program.workouts,
            now: this.now()
          })
          .then(response => {
            if (response.status == 200) {
              this.toast('Program Started')
              this.$router.push('/program/view/' + program.id)
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadPage() {
      this.loaded = false
      let url = '/programs/scroll/' + this.currentPage + '/' + this.pagesize
      return axios.get(this.baseURL + url, {}).then(response => {
        if (response.status == 200) {
          let newResponse = response.data.data
          newResponse.forEach(el => {
            if (el.workouts) {
              el.workouts = JSON.parse(el.workouts)
            } else el.workouts = []
          })

          if (!this.refresh) {
            this.programs = this.programs.concat(newResponse)
          } else {
            this.programs = newResponse
          }
          this.loadedList = this.programs.slice()
          this.loaded = true
          this.refresh = false
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            {
              root: true
            }
          )
          return
        }
      })
    }
  }
}
</script>
<style scoped>
addBtn {
  position: fixed;
  right: 5px;
  top: 90px;
  z-index: 100;
}

.addBtnPhone {
  position: fixed;
  right: 5px;
  top: 75px;
  z-index: 100;
}

.leftTools {
  position: fixed;
  left: 5px;
  opacity: 0.9;
  top: 90px;
}

.searchBox {
  position: absolute;
  width: 95%;
  top: 5px;
}

.searchBoxPhone {
  position: absolute;
  width: 95%;
  top: 85px;
}

.searchResults {
  margin-top: 175px;
}

.searchResultsNosearch {
  margin-top: 95px;
}

.fabsearch {
  border-radius: 0px 20px 20px 0px;
}
</style>

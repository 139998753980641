<template>
  <v-container>
    <v-dialog
      :fullscreen="isPhone"
      :width="dialogWidth"
      overlay-opacity=".95"
      overlay-color="charcoal"
      v-model="showReorderDialog"
      persistent
    >
      <ReorderList
        :items="workouts"
        type="Workouts"
        name="workouts"
        @clickedDone="doneReorder"
        @clickedCancel="showReorderDialog = false"
      />
    </v-dialog>
    <v-card tile flat class="transparent" v-touch:swipe="swipe">
      <v-form ref="ProgramForm" name="ProgramForm">
        <BaseScreenHeader title="Edit Program" screenInfo="PRG-002" :showBack="true" :showNav="true" />
        <v-card-text class="pa-0">
          <v-card-actions class="px-0">
            <v-switch
              dark
              ref="public"
              false-value="No"
              true-value="Yes"
              label="Public?"
              color="progressActive"
              v-model="program.public"
            />
            <v-spacer />
            <BaseTag
              :label="program.type"
              color="transparent silver--text"
              tagsize="medium"
              class="mb-1"
            />
          </v-card-actions>
          <v-sheet class="pa-2 charcoalTile transparent">
            <v-card-title class="pa-0 mb-1 paper--text">
              Name and Description
            </v-card-title>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  filled
                  dark
                  v-model="program.name"
                  ref="name"
                  :error-messages="nameErrors"
                  :counter="50"
                  label="Workout Name"
                  prepend-inner-icon="mdi-dumbbell"
                  @input="$v.program.name.$touch()"
                  @blur="$v.program.name.$touch()"
                />
              </v-col>
              <v-col cols="12" >
                <v-textarea
                  dark
                  filled
                  v-model="program.description"
                  ref="description"
                  :error-messages="descriptionErrors"
                  :counter="400"
                  :rows="5"
                  label="Description"
                  prepend-inner-icon="mdi-text"
                  @input="$v.program.description.$touch()"
                  @blur="$v.program.description.$touch()"
                />
              </v-col>
            </v-row>
          </v-sheet>
        </v-card-text>

        <v-card-actions class="pa-0 mt-2">
          <BaseActionButton
            dark
            label="Delete"
            :large="!isPhone"
            :title="'Delete Program: ' + program.name"
            plain
            color="orange"
            icon=""
            @clickedThis="setDeletable(program)"
          />
          <v-spacer />
          <BaseActionButton
            dark
            text
            class="charcoal paper--text"
            :disabled="$v.$anyError || workouts == 0"
            @clickedThis="saveProgram"
          />
        </v-card-actions>
        <v-sheet class="transparent charcoalTile px-2 my-4">
          <v-card-actions class="px-0 pb-0">
            <span class="title  paper--text">
              Sel. Workouts
            </span>
            <v-badge
              :content="workouts.length"
              :value="workouts.length"
              color="transparent paper--text"
              overlap
            >
              <v-icon large color="paper">
                mdi-checkbox-multiple-marked-circle
              </v-icon>
            </v-badge>
            <v-spacer />
            <v-btn
              text
              v-if="workouts.length > 0"
              class=" silver--text "
              @click="showReorderDialog = true"
            >
              Re-order
            </v-btn>
          </v-card-actions>
          <v-card-text class="pa-0">
            <WorkoutChip
              :type="program.type"
              :workouts="workouts"
              @remove="removeWorkout"
            />
          </v-card-text>
        </v-sheet>
        <WorkoutList
          :type="program.type"
          :workoutsList="workouts"
          @add_workout="addWorkout"
        />
      </v-form>

      <v-dialog
        v-model="deleteProgramFlag"
        :fullscreen="isPhone"
        persistent
        width="500"
      >
        <v-card align="stretch" elevation="20" v-if="deletable != null">
          <BaseCloseDialogHeader
            heading="Delete this program?"
            @cancel="cancelDelete"
          />
          <v-card-title color="" :class="textSizeXSmall + ' accent--text'">
            {{ deletable.name }}
          </v-card-title>
          <v-card-subtitle style="min-height: 200px" class="body-1 py-4">
            Are you sure you want to delete this program?
          </v-card-subtitle>
          <v-divider />
          <v-card-actions class="px-0">
            <BaseActionButton
              icon="mdi-close"
              color="info"
              label="Cancel"
              :large="!isPhone"
              plain
              @clickedThis="cancelDelete"
            />
            <v-spacer />
            <BaseActionButton
              icon="mdi-delete-circle-outline"
              label="Yes, Delete"
              :large="!isPhone"
              plain
              @clickedThis="deleteProgram"
            />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { appConfig, builder } from '@/store/helpers.js'
import axios from 'axios'
import { mapActions } from 'vuex'
import util from '@/mixins/util.js'

const WorkoutList = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/WorkoutsList.vue')
const WorkoutChip = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/WorkoutChip.vue')
const ReorderList = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/ReorderList.vue')

export default {
  components: {
    WorkoutList,
    WorkoutChip,
    ReorderList
  },
  name: 'EditProgram',
  mixins: [util, validationMixin],
  data: () => ({
    deleteProgramFlag: false,
    deletable: null,
    showReorderDialog: false,
    tagsize: 'small',
    byTime: true,
    search: '',
    active: 0,
    rest: 0,
    restExercise: 0,
    total: 0,
    rounds: 1,
    filter: {},
    sortDesc: false,
    page: 1,
    workout: {},
    loaded: false,
    allowEdit: false,
    workoutid: null,
    switchNum: 0,
    selectedForEdit: {},
    program: {},
    allworkouts: [],
    workouts: [],
    workoutsList: [],
    initialworkouts: [],
    name: '',
    oldname: '',
    description: ''
  }),
  props: {
    programid: {
      type: String
    }
  },
  beforeMount() {
    this.loadProgram(this.programid)
  },
  mounted() {
    this.scrollToTop()
  },
  validations: {
    program: {
      name: {
        required
      },
      description: {
        required
      }
    }
  },
  computed: {
    ...appConfig,
    ...builder,
    nameErrors() {
      const errors = []
      if (!this.$v.program.name.$dirty) return errors
      !this.$v.program.name.required && errors.push('Workout name is required.')
      return errors
    },
    descriptionErrors() {
      const errors = []
      if (!this.$v.program.description.$dirty) return errors
      !this.$v.program.description.required &&
        errors.push('Description is required.')
      return errors
    }
  },
  methods: {
    swipe(event) {
      if (event == 'left') this.$router.go(-1)
    },
    addWorkout(workout) {
      if (this.workouts == null) this.workouts = new Array()
      workout.sort = this.workouts.length + 1
      this.workouts.push(workout)
    },
    cancelDelete() {
      this.deleteProgramFlag = false
      this.deletable = {}
    },
    setDeletable(program) {
      this.deletable = program
      this.deleteProgramFlag = true
    },
    doneReorder() {
      this.showReorderDialog = false
      this.saveProgram()
    },
    saveExerciseOrder() {
      this.showReorderDialog = false
    },
    toggleDetails() {
      this.showDetails = !this.showDetails
    },
    removeWorkout(workout) {
      this.workouts = this.workouts.filter(el => {
        return el.workoutid != workout.workoutid
      })
      let i = 1
      this.workouts.forEach(el => {
        el.sort = i
        ++i
      })
    },
    saveProgram() {
      window.scrollTo(0, 0)
      return axios
        .post(this.baseURL + '/program', {
          programid: this.programid,
          name: this.program.name,
          description: this.program.description,
          public: this.program.public,
          workouts: this.workouts
        })
        .then(response => {
          if (response.status == 200) {
            this.loadProgram(this.programid)
            this.toast('Program was successfully saved.')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },

    loadProgram(programid) {
      return axios
        .get(this.baseURL + '/program/' + programid, {})
        .then(response => {
          if (response.status == 200) {
            this.program = response.data.data
            this.workouts = JSON.parse(this.program.workouts)
            if (this.workouts == null) this.workouts = new Array()
            this.initialworkouts = this.workouts.slice()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    deleteProgram() {
      return axios
        .delete(this.baseURL + '/program/' + this.deletable.id)
        .then(response => {
          if (response.status == 200) {
            this.deleteProgramFlag = false
            this.deletable = null
            this.toast('Program was successfully deleted.')
            this.$router.push('/myprograms')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    ...mapActions('builder', [
      'exerciseAdd',
      'exerciseRemove',
      'exerciseRemoveLast',
      'workoutsClear'
    ])
  }
}
</script>
